<template>
    <div v-if="loading" class="locker-loading loading-full-screen">
        <div class="snippet" data-title=".dot-pulse">
            <div class="stage">
                <div class="dot-pulse"></div>
            </div>
        </div>
    </div>

    <div v-if="!loading">
        <transition-group :name="direction" tag="div" class="locker-pages" id="locker-flow-pages" :class="location_slug">
            <section v-if="step == 0" class="locker-intro-page">
                <h2>
                    {{ $t('lockerflow.intro.title') }}
                    <template v-if="data.event">{{ data.event.attributes.name }}</template>
                    <template v-else>Elockers</template>
                </h2>

                <p>{{ $t('lockerflow.intro.description') }}</p>

                <!-- <p>Je kan hier een locker reserveren zonder dat je bij de balie in de rij hoeft te staan.
                Hoe werkt? Nou dat leggen we hieronder even uit!</p> -->

                <div class="description-steps">
                    <ul>
                        <!-- <li>Selecteer de size van de gewenste locker</li>
                        <li>Reken af in de checkout</li>
                        <li>Je krijgt het nummer door van je locker</li>
                        <li>Je ontvangt de pincode voor je locker</li>
                        <li>Stop je spullen in de locker en je bent klaar om te gaan!</li> -->

                        <li>{{ $t('lockerflow.intro.description-steps-1') }}</li>
                        <li>{{ $t('lockerflow.intro.description-steps-2') }}</li>
                        <li>{{ $t('lockerflow.intro.description-steps-3') }}</li>
                        <li>{{ $t('lockerflow.intro.description-steps-4') }}</li>
                        <li>{{ $t('lockerflow.intro.description-steps-5') }}</li>
                    </ul>
                </div>
            </section>

            <section v-else-if="step == event_step" class="event-step">
                <h2>{{ $t('lockers.select_event') }}</h2>

                <div v-for="(event, index) in events" :key="index">
                    <div
                        @click="selectEvent(event)"
                        class="btn btn-locker-flow"
                        :class="{ active: event.id == data.event.id }"
                        :data-event-id="event.id"
                        :data-event-name="replaceSpacesWithHyphen(event.attributes?.name)"
                    >
                        {{ event.attributes.name }}
                        <div class="additional-data">
                            <div>{{ formatDate(event.attributes.date) }}</div>
                        </div>
                    </div>
                </div>
            </section>

            <section v-else-if="step == location_step" class="location-step">
                <h2>{{ $t('lockers.select_location') }}</h2>

                <div v-for="(location, index) in locations" :key="index">
                    <div
                        @click="selectLocation(location)"
                        class="btn btn-locker-flow"
                        :class="{ active: location.id == data.location.id }"
                        :data-location-id="location.id"
                        :data-location-name="replaceSpacesWithHyphen(location.attributes?.name)"
                    >
                        {{ location.attributes.name }}
                    </div>
                </div>
            </section>

            <section v-else-if="step == area_step" class="area-step">
                <h2>{{ $t('lockers.select_area') }}</h2>

                <div v-for="(area, index) in areas" :key="index">
                    <div
                        @click="selectArea(area)"
                        class="btn btn-locker-flow"
                        :class="{ active: area.id == data.area.id }"
                        :data-area-id="area.id"
                        :data-area-name="replaceSpacesWithHyphen(area.attributes?.name)"
                    >
                        {{ area.attributes.name }}
                    </div>
                </div>
            </section>

            <section v-else-if="step == 4 - autoselected" class="lockers-step">
                <!-- <section v-else-if="step == 4 - autoselected"> -->

                <h2>{{ $t('lockers') }}</h2>

                <div>{{ $t('lockerflow.select_locker.description') }}</div>

                <div class="locker-products">
                    <div class="option-list">
                        <div
                            class="locker-item radius-anchor"
                            v-for="(item, index) in availableLockerTypes"
                            :key="index"
                            :class="{ active: hasLockerInCart(item.attributes.elockers_type) }"
                        >
                            <div class="product-content">
                                <div class="name">{{ item.attributes.description }}</div>
                                <div class="price">
                                    {{ $n(item.attributes.webshop_price, currency) }}
                                </div>
                            </div>

                            <div class="amount-wrapper">
                                <div class="amount radius-anchor">
                                    <button @click="subtractTicket(item.attributes.elockers_type)" class="minus">-</button>
                                    <div class="quantity">{{ this.getLockerLineByType(item.attributes.elockers_type, true) }}</div>
                                    <button @click="addTicket(item)" class="plus">+</button>
                                </div>

                                <div class="svg-wrapper">
                                    <svg class="animate-spin ml-3 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                        <path
                                            class="opacity-75"
                                            fill="currentColor"
                                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                        ></path>
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="mt-4">
                    <modal-cancel-transaction-timer />
                </div>
            </section>
        </transition-group>
    </div>
</template>

<script>
// libs
import moment from 'moment';
import * as _elockers from '../lib/elockers';
import * as _notification from '../lib/notification';
import * as _log from '../lib/log';
import * as _transaction from '../lib/transaction';
import * as _state from '../lib/state';
import * as _applicationState from '../lib/application-state';

// mixins
import ItemHelper from '../mixins/item.helper.js';

// components
import ModalCancelTransactionTimer from './ModalCancelTransactionTimer.vue';

const logger = _log.get('LOCKERS');

export default {
    name: 'LockerEventMode',
    mixins: [ItemHelper],
    props: ['location_slug', 'event_slug'],
    components: {
        ModalCancelTransactionTimer,
    },
    data() {
        return {
            loading: true,
            maximum_locker_amount: 2,
            events: [],
            locations: [],
            areas: [],
            direction: 'pages-forward',
            availableLockerTypes: [],
            step: 0,
            pincode: '1872',
            firstLockerStep: {
                step: 1,
                isSet: false,
            },
            stepRequiresAction: false,
            total_steps: 16,
            eventAutoselected: false,
            autoselected: 0,
            allTickets: [],
            event_step: 1,
            location_step: 2,
            area_step: 3,
            allowPreviousStep: true,
            data: {
                event: false,
                location: false,
                area: false,
                locker_type: false,
            },
        };
    },
    // watch: {
    //     transaction: {
    //         handler() {
    //             let lines = this.transaction.lines.filter((line) => line.tickets.length > 0);

    //             let quantity = 0;
    //             lines.forEach(function (line) {
    //                 quantity += line.quantity;
    //             });

    //             //get array of all tickets in transaction
    //             let allTickets = [];
    //             lines.forEach(function (line) {
    //                 line.tickets.forEach(function (ticket) {
    //                     allTickets.push(ticket);
    //                 });
    //             });
    //         },

    //         deep: true,
    //     },
    // },
    async mounted() {
        this.events = await _elockers.getEvents({
            event: this.$route.query.event,
            location: this.$route.query.location,
            area: this.$route.query.area,
            slug: this.event_slug,
        });

        console.log('this.events', this.events);

        this.setQueryParamSteps();

        // if (this.events.length === 1) {
        //     this.selectEvent(this.events[0], true);
        // }

        this.availableLockerTypes = await _elockers.getAvailableLockerTypes();
        if (this.hasTickets()) {
            this.nextStep('locker');
        }

        this.availableLockerTypes = await _elockers.getAvailableLockerTypes();

        this.loading = false;
    },
    methods: {
        getTotalSteps() {
            return this.total_steps;
        },

        previous() {
            this.direction = 'pages-back';

            if (this.step > 0) {
                this.step--;
            }

            document.querySelector('.overflow-stretcher').scrollTop = 0;
        },

        replaceSpacesWithHyphen(inputString) {
            return inputString.replace(/\s+/g, '-');
        },

        setQueryParamSteps() {
            let queryParams = Object.keys(this.$route.query);

            if (queryParams.includes('event') == true) {
                this.selectEvent(this.events[0], true);
                this.event_step = null;
                this.location_step--;
                this.area_step--;
                this.autoselected++;

                if (queryParams.includes('location') == true) {
                    this.selectLocation(this.locations[0], true);
                    this.location_step = null;
                    this.area_step--;
                    this.autoselected++;

                    if (queryParams.includes('area') == true) {
                        this.selectArea(this.areas[0], true);
                        this.area_step = null;
                        this.autoselected++;
                        this.allowPreviousStep = false;
                    }
                }
            }

            this.total_steps -= this.autoselected;
        },

        hasLockerInCart(type) {
            return this.transaction?.lines.filter((line) => line.elockers_type == type).length;
        },

        next(action = false) {
            this.direction = 'pages-forward';

            // necessary to process first step after autoselect event and clicking next.
            if (this.events.length == 1 && this.step == 0 && this.eventAutoselected == false && this.data.event == false) {
                this.eventAutoselected = true;
                this.selectEvent(this.events[0], false);
                this.step++;

                return;

                //necessary when event has been autoselected but there is only 1 location
            } else if (
                this.data.event != false &&
                this.eventAutoselected == false &&
                this.step == 0 &&
                this.data.event.relationships.locations.length == 1 &&
                this.data.location == false
            ) {
                this.eventAutoselected = true;
                this.selectLocation(this.data.event.relationships.locations[0], false);
                this.step++;

                return;
            }

            if (!this.loading && action != '-') {
                switch (this.step) {
                    case this.event_step:
                        if (!this.data.event) {
                            _notification.set('Please select event');
                            return false;
                        }
                        break;
                    case this.location_step:
                        if (!this.data.location) {
                            _notification.set('Please select location');
                            return false;
                        }
                        break;
                    case this.area_step:
                        if (!this.data.area) {
                            _notification.set('Please select area');
                            return false;
                        }
                        break;
                }
            }

            // reset to 4
            if (this.step == 4 - this.autoselected) {
                _applicationState.setLockerFlowStep(this.step + 1);
                _applicationState.setLockerFlowTotalSteps(this.total_steps);

                return true;
            }

            if (this.firstLockerStep.isSet === false && !this.loading) {
                this.firstLockerStep.isSet = true;
                this.step = this.firstLockerStep.step;
                // this.direction = 'pages-forward';
            } else if (this.step < this.total_steps) {
                this.step++;
                // this.direction = 'pages-forward';
            } else if (action == 'locker') {
                this.step = 4;
            }

            document.querySelector('.overflow-stretcher').scrollTop = 0;
        },

        selectEvent(event, autoselect = false) {
            console.log('SELECT EVENT');
            logger.log('selected event', event)();
            _transaction.setElockersEvent(event.id, event.attributes.name);
            this.data.event = event;
            this.locations = event.relationships.locations;
            // let queryParams = Object.keys(this.$route.query);

            //autoselect if there is 1 location and it has not been selected already by query params
            if (this.locations.length == 1 && !autoselect) {
                this.selectLocation(this.locations[0], false);
            }

            if (!autoselect) {
                this.$emit('next');
            }
        },

        selectLocation(location, autoselect = false) {
            logger.log('selected location', location)();
            _transaction.setElockersLocation(location.id, location.attributes.name);
            this.data.location = location;
            this.areas = location.relationships.areas;
            // let queryParams = Object.keys(this.$route.query);

            //autoselect if there is 1 area and it has not been selected already by query params
            if (this.areas.length == 1 && !autoselect) {
                this.selectArea(this.areas[0], false);
            }

            if (!autoselect) {
                this.$emit('next');
            }
        },

        selectArea(area, autoselect = false) {
            logger.log('selected area', area)();

            _transaction.setElockersArea(area.id, area.attributes.name);

            this.data.area = area;

            // if selected while loading increase the first step
            // if (this.loading) {
            //     this.firstLockerStep.step++;
            // }

            // if (autoselect) {
            //     this.total_steps--;
            // }

            if (!autoselect) {
                this.$emit('next');
            }
        },

        addTicket(item) {
            if (this.maximum_locker_amount === this.transaction.lines.reduce((count, current) => count + current.tickets.length, 0)) {
                _notification.set('Can only select ' + this.maximum_locker_amount + ' lockers at once.');
                return false;
            }

            let locker = this.getLockerLineByType(item.attributes.elockers_type);

            this.addItemByScannedBarcode(item);
        },

        subtractTicket(type) {
            let locker = this.getLockerLineByType(type);

            if (locker) {
                _transaction.subtractQuantity(locker);
            }
        },

        hasTickets() {
            return this.transaction.lines.find((line) => {
                return line.elockers_active === true;
            });
        },

        processedPincode(pincode) {
            let pincodeArray = pincode.split('');

            if (pincodeArray.length == 7) {
                pincodeArray.splice(3, 0, '');
            }

            return pincodeArray;
        },

        getLockerLineByType(type, getTickets = false) {
            let locker = this.transaction.lines.find((line) => {
                return line.elockers_type == type;
            });

            if (getTickets) {
                return locker ? locker.tickets.length : 0;
            }

            return locker;
        },

        moment() {
            return moment();
        },

        formatDate(date) {
            if (date) {
                return moment(date).format('DD-MM-YYYY');
            }
        },
    },
    computed: {
        transaction() {
            return _state.get('transaction/getTransaction');
        },

        config() {
            return _state.get('config/getConfig');
        },

        currency() {
            return this.config.settings.currency;
        },
    },
};
</script>
